import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, IconButton, Typography, Divider, List, ListItem, ListItemText, Drawer, Tooltip, Tabs, Tab, CircularProgress } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import notificationIcon from "../../../assets/images/notificationIcon.svg";
import markAllAsReadIcon from "../../../assets/images/markAllAsReadIcon.svg";
import notificationIconNew from "../../../assets/images/notificationIconNew.svg";
import { getUnreadNotification, getReadNotification, markAllAsReadNotification, getNotification } from "../../../Crud/rth";
import useWebSocket, { ReadyState } from "react-use-websocket";

interface Notification {
    id: number;
    message: string;
    read: boolean;
    notifiedAt: string;
}

interface NotificationDrawerProps {
    onToggleDrawer: () => void;
    open: boolean;
}

const NotificationDrawer: React.FC<NotificationDrawerProps> = ({ onToggleDrawer, open }) => {
    const [newNotification, setNewNotification] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [page, setPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const observer = useRef<IntersectionObserver | null>(null);
    const loadingRef = useRef<boolean>(false);
    const lastPageFetched = useRef<number>(-1);
    const pendingNotifications = useRef<Notification[]>([]);
    const userId = localStorage.getItem("userId") ?? "";
    
    // Format date consistently
    const formatNotificationDate = (date: string | Date) => {
        return new Date(date).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        }).replace(/, (\d{4})/, " $1 |").replace(" |,", " |");
    };

    // Check for unread notifications on component mount
    const checkForUnreadNotifications = useCallback(async () => {
        try {
            const response = await getUnreadNotification(userId, 0);
            const unreadExists = response.data?.data && response.data.data.length > 0;
            setNewNotification(unreadExists);
            console.log("Initial unread check:", unreadExists);
        } catch (error) {
            console.error("Error checking for unread notifications:", error);
        }
    }, [userId]);

    // Run the check on component mount - this ensures we check for unread notifications when component loads
    useEffect(() => {
        checkForUnreadNotifications();
    }, [checkForUnreadNotifications]);

    // WebSocket connection
    const socketUrl = `wss://mytheron-dev-backend.citpl.info/ws/notifications?userId=${userId}`;

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
       
        shouldReconnect: () => true,
        reconnectAttempts: 10,
        reconnectInterval: 5000,
        onOpen: () => {
            console.log("Connected to WebSocket");
        },
        onError: (event) => {
            console.error("WebSocket Error:", event);
        },
        onClose: (event) => {
            console.warn(`WebSocket closed, Code: ${event.code}, Reason: ${event.reason}`);
        },
    });

    console.log("sendMessage", sendMessage)

const lastProcessedMessage = useRef<string | null>(null);

useEffect(() => {
  if (lastMessage !== null) {
    console.log("Raw WebSocket message:", lastMessage.data);
    
    // Prevent reprocessing the same message
    if (lastProcessedMessage.current === lastMessage.data) {
      console.log("Duplicate message detected, skipping...");
      return;
    }
    lastProcessedMessage.current = lastMessage.data;

    setNewNotification(true);

    try {
      let data;
      try {
        data = JSON.parse(lastMessage.data);
      } catch (parseError) {
        console.log("Message is not JSON, treating as plain text notification");

        const newNotif = {
          id: Date.now(),
          message: lastMessage.data,
          read: false,
          notifiedAt: formatNotificationDate(new Date()),
        };

        if (!open) {
          pendingNotifications.current = [newNotif, ...pendingNotifications.current];
        } else if (selectedTab === 0) {
          setNotifications((prev) => [newNotif, ...prev]);
        }

        return;
      }

      if (data && (data.notificationId || data.id)) {
        console.log("Processing notification from WebSocket:", data);

        const notificationId = data.notificationId || data.id;
        const content = data.content || data.message;
        const notifiedAt = data.notifiedAt || new Date().toISOString();

        const newNotif = {
          id: notificationId,
          message: content,
          read: false,
          notifiedAt: formatNotificationDate(notifiedAt),
        };

        if (!open) {
          pendingNotifications.current = [newNotif, ...pendingNotifications.current];
        } else if (selectedTab === 0) {
          setNotifications((prev) => {
            if (!prev.some((n) => n.id === notificationId)) {
              return [newNotif, ...prev];
            }
            return prev;
          });
        }
      }
    } catch (error) {
      console.error("Error processing WebSocket message:", error, lastMessage.data);
    }
  }
}, [lastMessage, open, selectedTab]);


    // When drawer opens, add any pending notifications to the list
    useEffect(() => {
        if (open && selectedTab === 0 && pendingNotifications.current.length > 0) {
            setNotifications(prev => {
                const existingIds = new Set(prev.map(n => n.id));
                const newNotifs = pendingNotifications.current.filter(n => !existingIds.has(n.id));
                pendingNotifications.current = []; // Clear pending notifications
                return [...newNotifs, ...prev];
            });
        }
    }, [open, selectedTab]);

    useEffect(() => {
        setNotifications([]);
        setPage(0);
        setHasMore(true);
        lastPageFetched.current = -1;
        loadingRef.current = false;
    }, [selectedTab]);

    // Fetch notifications from API
    const fetchNotifications = useCallback(async () => {
        if (!hasMore || loadingRef.current || lastPageFetched.current === page) return;
        
        loadingRef.current = true;
        setLoading(true);
        console.log(`Fetching ${selectedTab === 0 ? 'unread' : 'read'} notifications, page ${page}`);

        try {
            const response = selectedTab === 0 
                ? await getUnreadNotification(userId, page) 
                : await getReadNotification(userId, page);
                
            console.log("API response:", response);
            const notificationsArray = response.data?.data || [];

            lastPageFetched.current = page;

            if (notificationsArray.length === 0) {
                setHasMore(false);
            } else {
                setNotifications(prev => {
                    // Convert API notifications to our format
                    const formattedNotifications = notificationsArray.map((notif: any) => ({
                        id: notif.notificationId,
                        message: notif.content,
                        read: notif.status === "Read",
                        notifiedAt: formatNotificationDate(notif.notifiedAt)
                    }));
                    
                    // If this is the first page and we're on the unread tab, update the indicator
                    if (page === 0 && selectedTab === 0) {
                        const hasUnread = formattedNotifications.length > 0;
                        setNewNotification(hasUnread);
                        console.log("Setting notification indicator based on API results:", hasUnread);
                    }
                    
                    // Filter out duplicates
                    const existingIds = new Set(prev.map(n => n.id));
                    const newNotifs = formattedNotifications.filter((n: { id: number; }) => !existingIds.has(n.id));
                    
                    return [...prev, ...newNotifs];
                });
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
        } finally {
            setLoading(false);
            loadingRef.current = false;
        }
    }, [hasMore, selectedTab, userId, page]);

    // Initial fetch and when page changes
    useEffect(() => {
        if (page !== lastPageFetched.current) {
            fetchNotifications();
        }
    }, [fetchNotifications, page]);

    // Check connection status and show visual indicator
    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    // useEffect(() => {
    //     console.log("WebSocket connection status:", connectionStatus);
    // }, [connectionStatus]);

    useEffect(() => {
        console.log("WebSocket connection status changed:", connectionStatus);
        if (connectionStatus === 'Open') {
          console.log("WebSocket connection is now open and ready to receive messages");
        }
      }, [connectionStatus]);

    const handleMarkAllAsRead = async () => {
        try {
            await markAllAsReadNotification(userId);
            setNotifications(prev => prev.map(notif => ({ ...notif, read: true })));
            setSelectedTab(1); // Switch to read tab
            setNewNotification(false); // Clear the notification indicator
            pendingNotifications.current = []; // Clear pending notifications
            console.log("Marked all as read, clearing indicator");
        } catch (error) {
            console.error("Error marking all notifications as read:", error);
        }
    };

    const handleNotificationClick = async (notificationId: number) => {
        try {
            await getNotification(notificationId.toString());
            
            // Update notification status locally
            setNotifications(prev => {
                const updatedNotifications = prev.map(notif =>
                    notif.id === notificationId ? { ...notif, read: true } : notif
                );
                
                // For unread tab, filter out read notifications
                if (selectedTab === 0) {
                    return updatedNotifications.filter(notif => !notif.read);
                }
                return updatedNotifications;
            });
            
            // Check if any unread notifications remain
            const anyUnreadLeft = notifications.some(notif => 
                notif.id !== notificationId && !notif.read
            );
            
            if (!anyUnreadLeft && pendingNotifications.current.length === 0) {
                setNewNotification(false);
                console.log("No unread notifications left, clearing indicator");
            }
        } catch (error) {
            console.error("Error marking notification as read:", error);
        }
    };

    // Setup intersection observer for infinite scrolling
    const lastNotificationRef = useCallback((node: HTMLLIElement | null) => {
        if (loading || !hasMore) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !loadingRef.current) {
                console.log("Last item visible, loading more notifications");
                setPage(prevPage => prevPage + 1);
            }
        }, { threshold: 0.5 });

        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    return (
        <>
            <IconButton 
                onClick={onToggleDrawer} 
                sx={{ marginLeft: "8px", position: "relative" }}
                data-testid="notification-button"
            >
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: 42 }}>
                    <img src={notificationIcon} alt="Notification Icon" style={{ height: "100%", width: "100%" }} />
                </Box>
                {newNotification && (
                    <Box 
                        sx={{ 
                            width: 8, 
                            height: 8, 
                            borderRadius: "50%", 
                            backgroundColor: "red", 
                            position: "absolute", 
                            top: 10, 
                            right: 10 
                        }}
                        data-testid="notification-indicator"
                    ></Box>
                )}
            </IconButton>
            <Drawer 
                anchor="right" 
                open={open} 
                onClose={onToggleDrawer}
                SlideProps={{
                    onEntered: () => {
                        // Force fetch when drawer opens
                        setNotifications([]);
                        setPage(0);
                        setHasMore(true);
                        lastPageFetched.current = -1;
                        loadingRef.current = false;
                    }
                }}
            >
                <div style={{ width: 300, padding: 16 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontSize: '1rem' }}>
                            Notifications {readyState === ReadyState.OPEN ? 
                                <Typography component="span" sx={{ fontSize: '0.75rem', color: 'green' }}>
                                    (live)
                                </Typography> : null
                            }
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title="Mark all as read" placement="left-start">
                                <IconButton onClick={handleMarkAllAsRead}>
                                    <img src={markAllAsReadIcon} alt="Mark All As Read" style={{ width: 18, height: 18 }} />
                                </IconButton>
                            </Tooltip>
                            <IconButton onClick={onToggleDrawer}>
                                <GridCloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)} variant="fullWidth">
                        <Tab label="Unread" style={{ fontSize: '0.875rem', textTransform: 'none' }} />
                        <Tab label="Read" style={{ fontSize: '0.875rem', textTransform: 'none' }} />
                    </Tabs>
                    <List>
                        {notifications.length === 0 ? (
                            loading ? (
                                <CircularProgress sx={{ display: "block", margin: "auto", mt: 2 }} />
                            ) : (
                                <Typography sx={{ padding: 2, textAlign: "center", fontSize: "0.875rem", color: "gray" }}>
                                    No Notifications
                                </Typography>
                            )
                        ) : (
                            notifications.map((notif, index) => (
                                <ListItem
                                    ref={index === notifications.length - 1 ? lastNotificationRef : null}
                                    key={`${notif.id}-${index}`}
                                    alignItems="flex-start"
                                    onClick={() => !notif.read && handleNotificationClick(notif.id)}
                                    sx={{ 
                                        cursor: notif.read ? "default" : "pointer",
                                        backgroundColor: notif.read ? "transparent" : "rgba(25, 118, 210, 0.05)"
                                    }}
                                >
                                    <IconButton sx={{ padding: 0, marginRight: 1, marginTop: 0.5 }}>
                                        <img src={notificationIconNew} alt="New Notification" style={{ width: 40, height: 40 }} />
                                    </IconButton>
                                    <ListItemText 
                                        primary={
                                            <Typography 
                                                sx={{ 
                                                    fontSize: "0.75rem", 
                                                    fontWeight: notif.read ? "normal" : "bold" 
                                                }}
                                            >
                                                {notif.message}
                                            </Typography>
                                        }
                                        secondary={
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Typography variant="caption" color="textSecondary">
                                                    {notif.notifiedAt}
                                                </Typography>
                                            </Box>
                                        } 
                                    />
                                </ListItem>
                            ))
                        )}
                        {loading && notifications.length > 0 && (
                            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                <CircularProgress size={24} />
                            </Box>
                        )}
                    </List>
                </div>
            </Drawer>
        </>
    );
};

export default NotificationDrawer;